import * as React from "react";
import Layout from "../components/Layout";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import icon from "../images/githl-mtn-icon.png"
import Button from "react-bootstrap/cjs/Button";
import SEO from "../components/Seo";
import Form from "react-bootstrap/cjs/Form";
import {MDBToastContainer, toast} from "mdbreact";
import Airtable from "airtable";

interface AboutProps {
    location: any;
}

interface AboutState {
    email: string
}

export default class IndexPage extends React.Component<AboutProps, AboutState> {
    constructor(props: AboutProps) {
        super(props)

        this.state = {
            email: ""
        }
    }

    handleSubmit = (event: any) => {
        toast.success('Thank you for joining the waitlist!', {
            closeButton: false
        });
        let base = new Airtable().base(process.env.GATSBY_AIRTABLE_WAITLIST_EMAILS_BASE_ID || "none");
        const fields = {"fields": {"Email": this.state.email}}
        this.setState({
            email: ""
        })
        base('waitlist_emails').create([
            fields
        ], function (err: any, records: any) {
            console.log(records)
            if (err) {
                console.error(err);
                return;
            }
            records.forEach(function (record: any) {
                console.log(record)
                console.log(record.getId());
            });
        })

        console.log(typeof event)
        event.preventDefault();
        event.target.reset();
    }

    handleEmailChange = (event: any) => {
        this.setState({
            email: event.target.value
        })
    }

    render() {
        return (
            <Layout location={this.props.location}>
                <SEO title="About Us"/>
                <div className="container pl-1 pr-1">
                    <div className="container d-flex align-self-center justify-content-center">
                        <Row className="justify-content-center align-self-center">
                            <Col className="logo-column" sm={6}>
                                <h1 className="logo-header-full text-center">
                                    <a href="/">
                                        <img
                                            className="icon-logo"
                                            src={icon}
                                            alt="gearinheadlights-icon"
                                        />
                                    </a>
                                </h1>
                                <h2>Welcome to gear in headlights!</h2>
                                <p className="paragraph">
                                    A new opportunity for your outdoor gear. Earn money renting out your outdoor gear while it's not in use. Standup paddleboards,
                                    road or mountain bikes, kayaks, canoes, rafts, skis, snowboards, snowshoes,
                                    surfboards, or other outdoor equipment welcome.
                                    <br/>
                                    Become a gearkeeper and earn income by renting out your gear, or become a gear
                                    rentee and access an abundance of outdoor opportunities at the click of a button!
                                    <br/>
                                    We are launching across North America in some of your favorite outdoor destinations
                                    from Denver, Colorado to Whitefish, Montana, Portland, Oregon, Salt Lake City, Utah, and beyond!
                                </p>
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Group controlId="email">
                                        <Form.Control type="email" placeholder="Enter email"
                                                      onChange={this.handleEmailChange}/>
                                    </Form.Group>
                                    <Button className="text-center shop-btn shadow" type="submit">
                                        JOIN THE WAITLIST
                                    </Button>
                                    <MDBToastContainer
                                        hideProgressBar={true}
                                        newestOnTop={true}
                                        autoClose={5000}
                                    />
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Layout>
        )
    }
}